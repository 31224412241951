import { useMemo } from 'react';

/**
 * Custom hook to determine the visibility of a banner based on date and time range in UTC time.
 *
 * @param {string} startDateTimeString - The start date and time of the visibility period in 'YYYY-MM-DDTHH:mm:ssZ' format.
 * @param {string} endDateTimeString - The end date and time of the visibility period in 'YYYY-MM-DDTHH:mm:ssZ' format.
 * @returns {boolean} - Returns true if the current date and time in UTC is within the given date and time range, otherwise false.
 *
 * @example
 * const isBannerVisible = useBannerVisibility('2024-07-17T16:00:00Z', '2024-07-27T00:00:00Z');
 * if (isBannerVisible) {
 *   // Display banner
 * }
 */
const useBannerVisibility = (startDateTimeString, endDateTimeString) => {
  return useMemo(() => {
    const startDateTime = Date.parse(startDateTimeString);
    const endDateTime = Date.parse(endDateTimeString);
    const now = Date.now();

    return now >= startDateTime && now <= endDateTime;
  }, [startDateTimeString, endDateTimeString]);
};

export default useBannerVisibility;
